




































import Vue from 'vue';
import { IProductDataservice } from '../../../shared/src/dataservices/IProductDataservice';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import SellProductsDialog from '../components/SellProductsDialog.vue';

export default Vue.extend({
  components: {
    SellProductsDialog
  },
   data() {
    return {
      sellProductsDialogVisible: false as boolean
    };
  },
  inject: {
    productDataservice: 'productDataservice',
    categoryDataservice: 'categoryDataservice'
  },
  methods: {
       async createProduct(): Promise<void> {
        const productDataservice = ((this as any).productDataservice as IProductDataservice);
        const productId = await productDataservice.addProduct();
        this.$router.push({ name: 'Product', params: { id: productId } });
      },
       async createCategory(): Promise<void> {
        const categoryDataservice = ((this as any).categoryDataservice as ICategoryDataservice);
        const categoryId = await categoryDataservice.addCategory();
        this.$router.push({ name: 'Category', params: { id: categoryId } });
      }
  }
});
