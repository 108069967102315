






















































































































import Vue from 'vue';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import { IProductDataservice } from '../../../shared/src/dataservices/IProductDataservice';
import { ProductStatus } from '../../../shared/src/entities/ProductStatus';
import { Product } from '../../../shared/src/entities/Product';
import { ISettingsDataservice } from '../../../shared/src/dataservices/ISettingsDataservice';

export default Vue.extend({
  inject: {
    categoryDataservice: 'categoryDataservice',
    productDataservice: 'productDataservice'
  },
  props: ['visible'],
   data() {
        return {
          search: '' as string,
          selectedProducts: [] as Product[]
      };
   },
    watch: {
      visible: {
        immediate: true,
        handler(newValue): void {
          if (newValue) {
            this.search = '';
            this.selectedProducts = [];
          }
        }
    }
  },
   computed: {
    faviconUrl(): string {
      return ((this as any).settingsDataservice as ISettingsDataservice).faviconUrl;
    },
    products(): Product[] {

      const productsForSale = ((this as any).productDataservice as IProductDataservice).getProductsWithStatus(ProductStatus.FOR_SALE);
      const productsForSaleExcludingSelected = productsForSale.filter( (product: Product) => !this.selectedProducts.includes(product));

      if (!this.search || this.search.length === 0) {
        return this.selectedProducts.concat(productsForSaleExcludingSelected);
      } else {
        return this.selectedProducts.concat( productsForSaleExcludingSelected.filter((product: Product) =>
           ((this as any).productDataservice as IProductDataservice).isProductMatchingSearch(product, this.search)));
      }

    },
    totalPrice(): number {
      let totalPrice = 0;
      this.selectedProducts.forEach((product) => {
        if (product.price) {
          totalPrice += Number(product.price);
        }
      });
      return totalPrice;
    }
  },
  methods: {
      toggleProductSelection(product: Product): void {
        if (!this.isProductSelected(product)) {
          this.selectedProducts.push(product);
        } else {
           const index = this.selectedProducts.findIndex((p) => p.id === product.id);
           this.selectedProducts.splice(index, 1);
        }
      },
      isProductSelected(product: Product): boolean {
        return this.selectedProducts.findIndex((p) => p.id === product.id) !== -1;
      },
      cancel(): void {
        this.$emit('cancel');
      },
      sellSelectedProducts(): void {
        this.selectedProducts.forEach((product) =>
          ((this as any).productDataservice as IProductDataservice).updateStatus(product.id, ProductStatus.SOLD));
        this.$emit('accept');
      },
      getDownloadUrlOfSmallProductImage(product: Product): string | undefined {
      if (!product || !product.images ) {
        return '';
      }

      const primaryImage = product.primaryImage;

      if (!primaryImage) {
        return '';
      }

      return primaryImage.smallImagedownloadUrl;
      }
    }
});
